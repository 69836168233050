.project-heading {
  text-align: center;
  padding: 4rem 0 2rem 0;
}

.project-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 5rem;
}

.project-card {
  background: #1a1919;
  padding: 1.2rem 1rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.project-card img {
  width: 100%;
}

.project-title {
  color: #fff;
  padding: 1rem 0;
}

.project-details p {
  padding-bottom: 1rem;
  font-size: 1.1rem;
  text-align: justify;
}

.project-btns {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.project-btns .btn {
  padding: .5rem 1rem;
}

.project-lower {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .project-container {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
  .project-container {
    max-width: 90%;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}