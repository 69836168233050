.hero-img {
  width: 100%;
  background: rgba(0, 0, 0, .5);
  height: 60vh;
  position: relative;
}

.hero-img::before {
  content: "";
  background: url("../assets/womanshopping.jpg");
  /* background: url("https: //images.pexels.com/photos/450035/pexels-photo-450035.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"); */
  height: 100%;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center top;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-img h1 {
  font-size: 2.4rem;
}

.hero-img p {
  font-size: 1.4rem;
  text-align: center;
  width: 75%;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading h1 {
  text-transform: uppercase;
}

@media screem and (max-width: 640px) {
  .hero-img h1 {
    font-size: 2rem;
  }
}